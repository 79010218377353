@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");
html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.stat-box {
  /* Add this property for the default size */
  transform: scale(1);
  transition: transform 0.2s ease;

  /* ... other styles ... */
}
.stat-box:hover {
  transform: scale(1.1); /* Increase size by 10% on hover */
  transition: transform 0.2s ease; /* Add a smooth transition effect */
}

/* Hover effect for the histogram bars */
.histogram-bar {
  fill: #69b3a2; /* Bar color */
  stroke-width: 1; /* Border width */
  transition: stroke-width 0.5s; /* Transition for border width */
}

.histogram-bar:hover {
  stroke-width: 5px;
}

.tooltip-hist {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none; /* Prevent the tooltip from interfering with mouse events */
  display: none; /* Initially hidden */
}

/* Style for the sidebar */
.sidebar {
  flex: 0 0 auto; /* or grid: none; for grid layout */
  width: 250px; /* Adjust as needed */
  /* Add other styling for your sidebar */
}

/* Style for the main panel */
.content {
  flex: 1; /* or grid: auto; for grid layout */
  overflow-y: auto; /* Enable vertical scrolling for the main panel */
  /* Add other styling for your main panel */
}

.accordion-summary {
  /* Styles for the accordion when open */
  color: initial;
  transition: color 0.3s;
}

.accordion-summary.closed:hover {
  /* Styles for the accordion when closed and hovered */
  color: #4cceac;
}
  .collapsed-link {
    color: red; /* Default color when collapsed */
    text-decoration: none;
    transition: color 0.3s ease; /* Smooth color transition */
  }
  
/* Add this CSS to your stylesheet */
.expanded-link {
  color: inherit; /* Inherit color from parent container */
  text-decoration: none; /* Remove underlines */
  transition: color 0.3s ease; /* Smooth color transition */
}

/* Change the color of visited links */
.expanded-link:visited {
  color: inherit; /* Color after link is visited */
}

/* Add a different color on hover */
.expanded-link:hover {
  color: #4cceac; /* Change the color on hover */
}



/* Styling for tooltip */
.tooltip {
  position: absolute;
  background-color: #333;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  pointer-events: none; /* Ensures the tooltip doesn't interfere with mouse events on the chart */
  z-index: 999; /* Make sure it appears on top of the chart */
}

/* Center the tooltip text */
.tooltip div {
  text-align: center;
}

/* Styling for tooltip on hover */
.histogram-bar:hover + .tooltip {
  opacity: 1; /* Show the tooltip on hover */
  transform: translate(-50%, -100%); /* Position above the bar */
}

.trips-line-container {
  display: flex;
  flex-direction: column;
  align-items: left; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: left;
  text-anchor: start;
  margin-top: 0px; /* Adjust the margin as needed */
  margin-bottom: 35px;
}

.line-tooltip {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none; /* Ensure the tooltip doesn't interfere with mouse events */
  display: none; /* Initially hidden */
  font-family: "Source Sans Pro", sans-serif; /* Apply the same font family as your app */
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  z-index: 999; /* Make sure it appears on top of the chart */
  color: #333; /* Text color */
}

.accordion-container {
  margin-left: 30px; /* Adjust the left margin as needed */
  margin-right: 30px; /* Adjust the right margin as needed */
}

.accordion-bar {
  /* Your existing bar styles */
  /* Add a transition for smooth animation */
  transition: filter 0.2s ease;
}

.accordion-bar.hovered {
  /* Apply a box-shadow glow when hovered */
  filter: drop-shadow(0 0 5px rgba(245, 245, 242, 0.812)); /* Adjust the shadow properties as needed */
}

.accordion-bar-label {
  opacity: 0;
  transition: opacity 0.5s; /* Adjust the duration as needed */
}

.notification-box {
  position: fixed;

  /* Add other styles like width, background color, etc. as needed */
}

.contact-form {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border 0.3s;
}

.contact-form input:focus, .contact-form textarea:focus {
  border-color: #007BFF;
  outline: none;
}

.contact-form input[type="submit"] {
  background-color: #007BFF;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form input[type="submit"]:hover {
  background-color: #0056b3;
}

.notification {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #4CAF50;  /* Green color */
  color: #4CAF50;  
  background-color: #e8f5e9; /* Light green background */
  border-radius: 5px;
  text-align: center;
}

.circlepack-tooltip {
  position: absolute;
  text-align: center;
  padding: 8px;
  font: 12px sans-serif;
  background: #141b2d;
  border: 0px solid;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10; /* make sure the tooltip is above all other elements */
}

@keyframes floatAnimation {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

.circleFloat {
  animation: floatAnimation 3s ease-in-out infinite;
}





